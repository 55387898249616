import * as React from "react"

const Lunch7 = () => (
  <div className="text-xl">
    <p>
      <em className="text-2xl">109 kr</em>
    </p>
    <p>
      Onsdag - fredag <em>11:30 - 14:30</em>
    </p>

    <p>
      <b>Onsdag</b>
      <br />
      Grillad kycklingfilé med sås på soltorkade tomater
      <br />
      Stekt torskfilé med pepparrotskräm & krispigt bacon
      <br />
      Pasta med skaldjur & limesås
    </p>

    <p>
      <b>Torsdag</b>
      <br />
      Köttgryta med rotfrukter, enbär & rosmarin
      <br />
      Stekt rödspätta med dillmajonnäs & citron
      <br />
      Pasta med fläskytterfilé, parmaskinka, champinjoner & gräddsås
    </p>

    <p>
      <b>Fredag</b>
      <br />
      Fläskfilé Black & White
      <br />
      Stekt sejryggfilé med basilikasås
      <br />
      Pasta med rökt skinka & gräddså
    </p>

    <p>
      <b>Veckans vegetariska</b>
      <br />
      Pasta med grönsaker, vitlök & tomatsås
    </p>

    <p>
      <em>inkl. sallad, hembakat bröd, dryck samt kaffe/te och kaka</em>
      <br />
      Avhämtning: 99 kr
    </p>
  </div>
)

export default Lunch7
